<template>
    <v-dialog v-model="dialog" max-width="800">
        <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">

        <v-card>
            <v-card-title>
                {{$t('choose_store_location')}}
            </v-card-title>
            <v-card-text>
                <iframe style="border: none;" :src="iframeSrc" @load="iframeLoaded" ref="iframe" width="100%" height="300"></iframe>
                <ValidationProvider ref="map_id"
                                    rules="required|min:1|max:100"
                                    v-slot="{ errors, valid }">
                    <v-text-field v-model="map_id" type="text" :error="!valid"
                                  :error-messages="errors"
                                  :disabled="true"
                                  :label="$t('office')" color="primary">
                    </v-text-field>
                </ValidationProvider>
            </v-card-text>
            <v-card-text>

            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="closeDialog"> {{ $t('close') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="send()" :disabled="invalid || loading"
                       :loading="loading" color="primary">
                    {{ $t('save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    name: 'MapMallDialog',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            loading: false,
            dialog: false,
            iframeSrc: '',
            map_id: null,
        };
    },
    methods: {
        async getUrlMap(lang, floor){
            this.loading = true;
            let params = {}
            params.language = lang;
            if(floor){
                params.floor = floor;
            }
            await this.$http
                .get(`admin/url_map`, {params: params})
                .then(res => {
                    if( res.body.data &&  res.body.data.url){
                        this.iframeSrc = res.body.data.url
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_map_url'));
                    this.iframeSrc = null
                })
                .finally(end => {
                    this.loading = false
                });
        },
        openDialogWithLink(lang, floor) {
            this.getUrlMap(lang, floor)
            this.map_id = null;
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
            this.iframeSrc = '';
        },
        send(){
            this.$emit('sendMapDialogParams', this.map_id);
            this.closeDialog();
        },
        iframeLoaded() {
            window.addEventListener('message', this.handleMessage);
        },
        handleMessage(event) {
            if (event.data && event.data.param) {
                const paramFromIframe = event.data.param;
                if(paramFromIframe.data && paramFromIframe.data.map_id){
                    this.map_id = paramFromIframe.data.map_id
                }
            }
        }
    }
};
</script>
